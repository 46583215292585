<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc
      </h5>
      <div class="d-flex gap-1 align-items-center">
        <b-button
          v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
          variant="flat-primary"
          size="sm"
          class="p-0"
          @click="handleClearSearch"
        >
          <feather-icon
            :icon="searching ? 'SettingsIcon' : 'XSquareIcon'"
            size="24"
            class="cursor-pointer"
            :class="searching ? 'spinner' : ''"
          />
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSearch"
        >
          Tìm kiếm
        </b-button>
      </div>
    </b-card-header>
    <div class="card-body">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >
          <!--              tỉnh-->
          <dynamic-form
            :id="'filter-form-tinh'"
            :value="value.province_id"
            :type="'radio'"
            :label="'Tỉnh'"
            value-field="id"
            :class="'form-group-hor'"
            :options="PROVINCE"
            @update-value="val => value.province_id = val"
          />

          <!--              hình thức-->
          <!--          <dynamic-form-->
          <!--            v-show="false"-->
          <!--            :id="'filter-form-hinh-thuc'"-->
          <!--            :value="value.formality"-->
          <!--            :type="'radio'"-->
          <!--            :label="'Hình thức'"-->
          <!--            :class="'form-group-hor'"-->
          <!--            :options="FORMALITY_PRODUCT.filter(item => item.validBy.includes(productMenuTypeVI))"-->
          <!--            @update-value="val => value.formality = val"-->
          <!--          />-->

          <!--              loại-->
          <!--          <dynamic-form-->
          <!--            :id="'filter-form-loai'"-->
          <!--            :value="value.type"-->
          <!--            :type="'select'"-->
          <!--            :label="'Loại'"-->
          <!--            :select-default="false"-->
          <!--            :class="'form-group-hor'"-->
          <!--            :options="[{text: 'Tất cả', value: ''}, ...TYPE_PRODUCT.filter(item => item.validBy.includes(value.formality))]"-->
          <!--            @update-value="val => value.type = val"-->
          <!--          />-->

          <div class="form-group-hor mb-1">
            <label>Loại</label>
            <div class="font-weight-bolder text-primary">
              Khách sạn & CHDV
            </div>
          </div>

          <!--            đặc biệt-->
          <dynamic-form
            :id="'filter-form-dacbiet'"
            :value="value.has_corner"
            :type="'checkbox'"
            :label="'Đặc biệt'"
            :sub-label="'Sản phẩm có góc'"
            :class="'form-group-hor'"
            @update-value="val => value.has_corner = (val ? 1 : '')"
          />

        </b-col>
        <b-col
          cols="12"
          lg="4"
        >

          <!--              quận huyện-->
          <dynamic-form
            :id="'filter-form-quanhuyen'"
            :value="value.district_id_in"
            :type="'multi-select'"
            :label="'Quận/Huyện'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="districts"
            :select-default="false"
            @update-value="val => value.district_id_in = val"
          />

          <!--              Phường/Xã-->
          <dynamic-form
            v-if="!value.district_id_in || value.district_id_in.length < 2"
            :id="'filter-form-phuongxa'"
            :value="value.ward_id_in"
            :type="'multi-select'"
            :label="'Phường/Xã'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="wardsByDistricts"
            :select-default="false"
            @update-value="val => value.ward_id_in = val"
          />

          <!--              Đường-->
          <dynamic-form
            :id="'filter-form-duong'"
            :value="value.street_name_in"
            :type="'multi-select'"
            :label="'Đường'"
            value-field="name"
            text-field="name"
            :class="'form-group-hor'"
            :options="streetsByDistricts"
            :select-default="false"
            @update-value="val => value.street_name_in = val"
          />

          <!--              Số nhà-->
          <dynamic-form
            :id="'filter-form-sonha'"
            :value="value.house_number"
            :type="'text'"
            :label="'Số nhà (chứa)'"
            :class="'form-group-hor'"
            @update-value="val => value.house_number = val"
          />

        </b-col>

        <b-col
          cols="12"
          lg="4"
        >
          <!--          <div-->
          <!--            class="form-group-hor mb-1"-->
          <!--          >-->
          <!--            <label>Số lầu</label>-->
          <!--            <div class="grid-2-columns">-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.floor_number"-->
          <!--                  placeholder="Tối thiểu"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.max_floor_number"-->
          <!--                  placeholder="Tối đa"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="form-group-hor mb-1">
            <label>Ngang x Dài</label>
            <div class="grid-2-columns">
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.width"
                  placeholder="Tổi thiểu"
                />
              </b-input-group>
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.length"
                  placeholder="Tổi thiểu"
                />
              </b-input-group>
            </div>
          </div>

          <!--            Diện tích-->
          <dynamic-form
            :id="'filter-form-acreage'"
            :value="value.acreage"
            :type="'number'"
            placeholder="Tổi thiểu"
            :label="'Diện tích'"
            :class="'form-group-hor'"
            append-text="≤"
            @update-value="val => value.acreage = val"
          />

          <!--          số phòng-->
          <!--          <dynamic-form-->
          <!--            :id="'filter-form-roomNumberTotal'"-->
          <!--            :type="'number'"-->
          <!--            placeholder="Số phòng"-->
          <!--            :label="'Số phòng'"-->
          <!--            :class="'form-group-hor'"-->
          <!--            @update-value="val => value.room_number = val"-->
          <!--          />-->

          <!--            Giá-->
          <div class="form-group-hor mb-1">
            <label>Giá</label>
            <div class="d-flex flex-column gap-25">
              <div class="d-flex gap-25">
                <b-input-group
                  prepend="≥"
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="price.from"
                    placeholder="Tối thiểu"
                    @input="updateFilterPrice"
                  />
                </b-input-group>

                <v-select
                  id="filter-form-price-from-currency"
                  v-model="price.currency"
                  class="v-select-no-wrap"
                  :reduce="(option) => option.value"
                  :label="'text'"
                  :options="currencyList"
                  :clearable="false"
                  @input="updateFilterPrice"
                />
              </div>
              <div class="d-flex gap-25">
                <b-input-group
                  prepend="≤"
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="price.to"
                    placeholder="Tối đa"
                    @input="updateFilterPrice"
                  />
                </b-input-group>
                <v-select
                  id="filter-form-price-to-currency"
                  v-model="price.currency"
                  class="v-select-no-wrap"
                  :reduce="(option) => option.value"
                  :label="'text'"
                  :options="currencyList"
                  :clearable="false"
                  @input="updateFilterPrice"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormInput, BInputGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useProductList from '@/views/product-hotel/list/useProductList'
import { reactive, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BCardHeader,
    BFormInput,
    BInputGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currencyList() {
      return this.CURRENCY.filter(item => item.validBy.includes('vnd')) || []
    },
  },
  methods: {
    updateFilterPrice() {
      this.value.currency = this.price.currency
      this.value.price_from = this.getPrice(this.price.from, this.value.currency)
      this.value.price_to = this.getPrice(this.price.to, this.value.currency)
    },
    getPrice(val, currency) {
      let price
      switch (currency) {
        case 'million':
          price = val * 1000000
          break
        case 'billion':
          price = val * 1000000000
          break
        default:
          price = val
          break
      }
      return price || ''
    },
  },
  setup(props, { emit }) {
    const searching = ref(null)

    const price = reactive({
      from: ref(''),
      to: ref(''),
      currency: ref('million'),
    })

    const {
      wardsByDistricts, streetsByDistricts, districts, users, productListMenuType, productMenuType, productMenuTypeVI,
    } = useProductList()

    const handleSearch = () => {
      emit('update:loading', true)
      if (searching.value) {
        clearTimeout(searching.value)
        searching.value = null
      }
      // new search delay
      searching.value = setTimeout(() => {
        emit('search')
        // waiting for vue update -> parent -> child(here)
        setTimeout(() => {
          clearTimeout(searching.value)
          searching.value = null
        }, 50)
      }, 500)
    }

    const handleClearSearch = () => {
      searching.value = null
      emit('clear-filter', { province_id: 1, type: 'khach-san', product_type: 'hotel' })
    }

    watch(props.value, () => {
      handleSearch()
    })
    watch([productListMenuType, productMenuType], () => {
      handleClearSearch()
    })

    return {
      price,
      searching,
      districts,
      productListMenuType,
      users,
      wardsByDistricts,
      productMenuType,
      productMenuTypeVI,
      streetsByDistricts,
      handleSearch,
      handleClearSearch,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
</style>
